export default {
    data(){
        return {
            /**
             * 游戏难度 0:初级，1:中级，2:高级
             */
            gameGrade: 0,
            /**
             * 游戏区域宽度
             */
            winWidth: document.body.offsetWidth,
            /**
             * 游戏区域高度
             */
            winHeight: document.body.offsetHeight,
            /**
             * 定时器集合
             */
            timerList: {}
        }
    },
    created() {
        // this.gameStart();
    },
    methods:{
        gameStart(){
            this.$nextTick(() => {
                //生成纸牌数据
                this.generateCardData()
                this.createContainer();
                this.createCardPool();
                this.createCard();
                this.createCardBox();

                this.$el.onmousemove = this.onmousemoveHandle;
                this.$el.onmouseenter = this.onmouseenterHandle;
                this.$el.onmouseleave = this.onmouseleaveHandle;

            })
        },
        /**
         * 游戏区域鼠标移动事件
         */
        onmousemoveHandle(e) {
            let result = undefined;
            for (let key in this.containerList) {
                let item = this.containerList[key];
                if(item.position.left <= e.clientX && item.position.top <= e.clientY && item.position.left + item.position.width >= e.clientX && item.position.top + item.position.height >= e.clientY){
                    result = item;
                    break;
                }
            }
            if(result === undefined && this.currentContainer !== undefined){
                this.currentContainer = undefined;
            } else if (result && (!!!this.currentContainer || result.id !== this.currentContainer.id)){
                this.currentContainer = result;
            }
        },
        onmouseenterHandle(e) {
            // console.log('鼠标进入', e)
        },
        onmouseleaveHandle(e) {
            if(this.colCardIds && this.colCardIds.length > 0){
                this.cardMouseup(this.colCardIds[0]);

                document.onmousemove = null;

            }
            // console.log('鼠标退出', e)
        },
        /**
         * 情理数据
         */
        clearData(){
            //销毁所有延时器，处理快速点击新游戏导致游戏数据异常
            this.destroyDelayExecute();
            this.currentContainer = undefined;
            this.cardDataList = [];

            this.cardElList = {};
            this.colCardIds = [];
            this.colCardEls = [];


            let objList = [this.containerList, this.cardList, this.cardPoolList, this.cardBoxList];
            for (let i = 0; i < objList.length; i++) {
                let list = objList[i];
                for(let id in list){
                    let el = this.getElement(id);
                    if(el){
                        el.remove();
                    }
                }
            }

            this.containerList = {};
            this.cardList = {};
            this.cardPoolList = {};
            this.cardBoxList = {};

        },
        /**
         * 生成id
         * @return {string}
         */
        generateId(){
            return `${new Date().getTime()}&${Math.random()}`
        },

        /**
         * 通过id获取元素
         * @param id
         * @return {HTMLElement}
         */
        getElement(id){
            return document.getElementById(id);
        },
        getAppElement(){
            return this.getElement("app");
        },
        /**
         * 提示条
         * @param message 提示信息
         * @param timeout 关闭时间
         * @return {HTMLDivElement}
         */
        showToast(message, timeout = 1000) {
            let el = document.createElement("div");
            el.style.position = "fixed";
            el.style.width = "100vw";
            el.style.height = "80px";
            el.style.lineHeight = "80px";
            el.style.color = "white";
            el.style.textAlign = "center";
            el.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
            el.style.bottom = "80px";

            el.style.zIndex = `${Math.pow(2, 31)}`;
            el.innerText = message;

            el.style.transition = `0.2s`;

            document.body.appendChild(el);
            el.close = el.remove;
            //延时关闭
            this.delayExecute(() => {
                el.style.opacity = "0";
                this.delayExecute(() => {
                    el.remove();
                }, 200)

            }, timeout, () => {
                el && el.remove();
            })
            return el;
        },
        /**
         * 延时执行 (把延时器记录起来，方便重置游戏时，统一销毁)
         * @param fun 执行函数
         * @param timeout 执行延时时间
         * @param destroyFun 销毁执行函数 如果为逻辑值true 则执行定时器函数
         */
        delayExecute(fun, timeout, destroyFun ){
            let timerId = this.generateId();
            this.timerList[timerId] = {
                timer: setTimeout(() => {
                    fun();
                    this.destroyDelayExecute(timerId, true);
                }, timeout),
                destroyFun: ((destroyFun instanceof Boolean && destroyFun) ? fun : destroyFun)
            }

        },
        /**
         * 销毁延时执行器
         * @param timerId 延时器id 为空则清除所有
         * @param isFinish 执行完成销毁
         */
        destroyDelayExecute(timerId, isFinish){
            if(timerId){
                let timerObj = this.timerList[timerId];
                if(timerObj && timerObj.timer){
                    if(timerObj.timer){
                        clearTimeout(timerObj.timer);
                    }
                    if(!isFinish && timerObj.destroyFun instanceof Function){
                        try {
                            timerObj.destroyFun();
                        }catch (e){

                        }
                    }
                }
                delete this.timerList[timerId];
            }else{
                for(let key in this.timerList){
                    this.destroyDelayExecute(key);
                }
            }
        },
        /**
         * 撤销
         */
        revoke(){
            let step = this.cardHistoryList.pop();
            if(step){
                this.menuValue.allowRevoke = this.cardHistoryList.length > 0;

                if(step.openCardId) {
                    let openCard = this.cardList[step.openCardId];
                    //合上翻开的纸牌
                    openCard.value.status = 0;

                    let openCardEl = this.cardElList[step.openCardId];

                    openCardEl.showInfo(openCard.value);
                }
                this.receivingCard(step.containerId, step.cardIds, false, true);
            }else{
                this.showToast("不给撤销了，再撤销我生气了")
            }

        },
        /**
         * 清除操作历史记录
         */
        clearHistory(){
            this.cardHistoryList = [];
            this.menuValue.allowRevoke = false;
        }
    }
}
<template>
  <div ref="container" class="container" :style="{
    width:`${position.width}px`,
    height:`${position.height}px`,
    top:`${position.top}px`,
    left:`${position.left}px`
  }"
  ></div>
</template>

<script>
export default {
  name: "Container",
  props:{
    /**
     * 组件id （用于区分组件）
     */
    id: String,
    /**
     * 位置信息
     */
    position: Object,
    /**
     * 回调函数
     */
    callback: Function
  },
  created() {
    // this.$nextTick(() => {
    //   this.$refs.container.style.top = this.value.top;
    //   this.$refs.container.style.left = this.value.left;
    //   this.$refs.container.style.offsetWidth = this.value.width;
    //   this.$refs.container.style.offsetHeight = this.value.height;
    // })
    // this.$nextTick(() => {
    //   this.$el.onmousemove = this.onmousemoveHandle;
    //   this.$el.onmouseenter = this.onmouseenterHandle;
    //   this.$el.onmouseleave = this.onmouseleaveHandle;
    // })

  },
  methods: {

  }
}
</script>

<style scoped>
.container{
  /*background-color: rgba(50, 50, 50, 0.5);*/
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.container:after{
  content: ' ';
  display: block;
  position: absolute;
  width: 92px;
  height: 129px;
  border: 4px solid rgba(0,0,0,0.4);
  border-radius: 8px;
  box-sizing: content-box;

  /*background-image: url("@/assets/empty.png");*/
  /*background-repeat: no-repeat;*/
  /*background-position: center top;*/
}
</style>
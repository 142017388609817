<template>
  <div ref="card" class="card"/>
</template>

<script>
export default {
  name: "Card",
  props:{
    id: String,
    /**
     * 牌数据
     * type: 牌花色 (0:方块，1:梅花，2:桃花，3:黑桃)
     * num: 牌数 (1:A，11:J，12:Q，13:K)
     * status: 牌状态(0:未翻牌，1:已翻牌，2:已完成)
     */
    value: Object,
    position: Object,
    cardMousedown: Function,
    cardMouseup: Function,
    cardClick: Function
  },
  data(){
    return {
      newValue: undefined,
      img:{
        //背景图
        cardBack: require("@/assets/cardBack.png"),
        //花色
        decor: undefined
      }
    }
  },
  created() {
    this.$nextTick(() => {

      if(this.position){
        let card = this.$refs.card;
        if(this.position.left){
          card.style.left = `${this.position.left}px`;
        }
        if(this.position.top){
          card.style.top = `${this.position.top}px`;
        }
      }
      if(this.cardMousedown){
        this.$el.onmousedown = this.mousedown;
      }
      if(this.cardMouseup){
        this.$el.onmouseup = this.mouseup;
      }
      if(this.cardClick){
        this.$el.onclick = this.click;
      }
      this.showInfo();
    })
  },
  computed:{
    valuePreview(){
      return this.newValue || this.value
    }
  },
  methods:{
    showInfo(value = undefined) {
      if (value !== undefined) {
        this.newValue = value;
      }
      let backgroundImage;
      if (this.valuePreview.status === 0 || this.valuePreview.status === 2) {
        backgroundImage = `url(${this.img.cardBack})`
      } else {
        this.draw();
      }

      this.$nextTick(() => {
        this.$refs.card.style.backgroundImage = backgroundImage;
      })

    },
    /**
     * 绘制
     */
    draw(){
      //花色图片
      // this.img.decor = require(`@/assets/card/type${this.valuePreview.type}.png`);
      let type
      switch (this.valuePreview.type) {
        case 0:
          type = "d"
          break;
        case 1:
          type = "c"
          break;
        case 2:
          type = "h"
          break;
        case 3:
          type = "s"
          break;
      }
      this.img.decor = require(`@/assets/card/img/${this.valuePreview.num}_${type}.png`);

      this.$nextTick(() => {

        this.$refs.card.style.backgroundImage = `url(${this.img.decor})`;

      })
    },
    mousedown(e){
      this.cardMousedown(this.id, this, e);
    },
    mouseup(e) {
      this.cardMouseup(this.id, this, e);
    },
    click(e){
      this.cardClick(this.id, this, e);
    }

  }

}
</script>

<style scoped>
.card{
  width: 100px;
  height: 137px;
  text-align: center;
  background-color: white;
  background-size: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 2px rgba(50,50,50,0.3);
  position: fixed;
  z-index: 2;
}
</style>
import domCreate from "@/utils/DomCreate";
import Container from "@/components/Container";

export default {
    data(){
        return {
            /**
             * 当前鼠标所属容器
             */
            currentContainer: undefined,
            /**
             * 容器集合
             */
            containerList:{},
            /**
             * 牌间隔
             */
            cardSpace: {
                //牌数量大于等于0
                "0": {
                    //明牌
                    openDeal: 45,
                    //暗牌
                    darkCard: 20,
                },
                //牌数量大于等于15
                "15": {
                    //明牌
                    openDeal: 35,
                    //暗牌
                    darkCard: 16,
                },
                "20": {
                    //明牌
                    openDeal: 25,
                    //暗牌
                    darkCard: 12,
                },
                "25": {
                    //明牌
                    openDeal: 20,
                    //暗牌
                    darkCard: 10,
                },
                "30": {
                    //明牌
                    openDeal: 15,
                    //暗牌
                    darkCard: 8,
                }

            },
            /**
             * 容器数量
             */
            containerNum: 10
        }
    },
    components:{
        Container
    },
    methods:{
        /**
         * 创建容器
         */
        createContainer(){
            let space = 10;
            let cellW = this.winWidth / this.containerNum;
            let containerTop = 180;
            for (let i = 0; i < this.containerNum; i++) {
                let position = {
                    top: containerTop,
                    left:cellW * i + space,
                    width: cellW - space * 2,
                    height:this.winHeight - containerTop
                };
                let id = this.generateId();
                let props = {id, position, cardIds: []};
                this.containerList[id] = {...props};
                domCreate(Container, props, { id });
            }
        },
        /**
         * 检查接收合法性(容器是否允许存放牌)
         * @param containerId 容器id
         * @param cardId 纸牌id
         */
        checkReceivingLegitimacy(containerId, cardId){

            let index = this.findCardInContainerIndex(containerId, cardId);
            //如果该牌已存在该容器，则直接合法
            if(index !== -1){
                return true;
            }
            //获取该容器的最后一张牌id
            let lastCardId = this.getLastCardByContainerId(containerId);
            if(lastCardId){
                //最后一张牌的信息
                let lastCard = this.cardList[lastCardId];
                //当前牌信息
                let card = this.cardList[cardId];

                if(lastCard.value.num === card.value.num + 1){
                    //如果最后一张牌是当前牌的点数 + 1，则合法
                    return true;
                }

            }else{
                //如果不存在牌，则合法
                return true;
            }

            //接收该牌不合法
            return false;
        },
        /**
         * 接收牌
         * @param containerId 容器id
         * @param receivingCardIds 欲接收纸牌id集合
         * @param isLicensing 是否为发牌
         * @param isRevoke 是否为撤销
         */
        receivingCard(containerId, receivingCardIds, isLicensing = false, isRevoke = false){
            //判断该容器接收该牌是否合法
            if(!this.checkReceivingLegitimacy(containerId, receivingCardIds[0]) && !isLicensing && !isRevoke){
                //如不合法，则返回false;
                return false;
            }

            let history = {
                cardIds: receivingCardIds
            };

            for (let i = 0; i < receivingCardIds.length; i++) {
                let cardId = receivingCardIds[i];
                let card = this.cardList[cardId];

                let oldContainerId = card.containerId;
                //存在容器，并不是原容器
                if(oldContainerId && card.containerId !== containerId){
                    let oldContainer = this.containerList[oldContainerId];
                    let oldCard = oldContainer.cardIds.filter(cardIdTemp => cardIdTemp === cardId);
                    if(oldCard){
                        let index = this.findCardInContainerIndex(card.containerId, cardId);
                        if(index !== -1){
                            oldContainer.cardIds.splice(index,1);

                            let oldContainerLastCardId = this.getLastCardByContainerId(oldContainerId);
                            if(oldContainerLastCardId !== null) {
                                let oldContainerLastCard = this.cardList[oldContainerLastCardId];
                                //如果该纸牌为未翻开的，则翻开处理
                                if(oldContainerLastCard.value.status === 0){
                                    //翻开纸牌
                                    oldContainerLastCard.value.status = 1;
                                    //记录操作记录翻开的纸牌id;
                                    history.openCardId = oldContainerLastCardId;

                                    let oldContainerLastCardEl = this.cardElList[oldContainerLastCardId];

                                    oldContainerLastCardEl.showInfo(oldContainerLastCard.value);
                                }
                            }

                            //调整牌间距
                            this.adjustCardSpace(oldContainerId);
                        }
                    }
                }

                let container = this.containerList[containerId];
                let cardIds = container.cardIds;
                let cardEl = this.getElement(cardId);
                let containerEl = this.getElement(containerId);
                let left = containerEl.offsetLeft + (containerEl.clientWidth - cardEl.clientWidth) / 2;
                let top = containerEl.offsetTop;

                let index = this.findCardInContainerIndex(containerId, cardId);
                for (let i = 0; i < cardIds.length; i++) {
                    let cardIdTemp = cardIds[i];
                    let cardTemp = this.cardList[cardIdTemp];
                    let cardSpace = cardTemp.value.status === 0 || cardTemp.value.status === 2 ? this.cardSpace["0"].darkCard : this.cardSpace["0"].openDeal;

                    if(index === i){
                        break;
                    }
                    top += cardSpace;

                }


                //获取当前容器内最后一张纸牌id
                if (index === -1){

                    let lastCardId = this.getLastCardByContainerId(containerId);
                    if(lastCardId){
                        //延时插入，等播放完成动画
                        this.delayExecute(() => {
                            //调整元素层级
                            this.getElement(lastCardId).after(cardEl);
                        }, this.cardAnimationDuration)
                    }
                    cardIds.push(cardId);

                }

                this.moveCard(cardId, left, top);

                card.containerId = containerId;

                //接收的最后一张牌
                if(i === receivingCardIds.length - 1) {
                    //如果存在旧容器id且不是原容器，且不为撤销操作，则记录操作历史
                    if(oldContainerId && oldContainerId !== containerId && !isRevoke){
                        history.containerId = oldContainerId;
                        this.cardHistoryList.push(history);
                        this.menuValue.allowRevoke = true;
                    }

                    //标识是否存在完整牌列
                    let flag = false;
                    if(card.value.num === 1){
                        //欲完成的纸牌id集合
                        let completeCardIds = [cardId];
                        //当前纸牌数值
                        let nowNum = 1;
                        //A牌的花色
                        let aCardType = card.value.type;
                        for (let j = cardIds.length - 2; j >= 0; j--) {
                            let cardTempId = cardIds[j];
                            let cardTemp = this.cardList[cardTempId];
                            //如果不是当前纸牌数值加1 或者 类型不匹配则跳出循环  -- (没有完整的牌列)
                            if(++nowNum !== cardTemp.value.num || aCardType !== cardTemp.value.type){
                                flag = false;
                                break;
                            }else if(nowNum === 13){
                                flag = true;
                                //清空操作历史记录
                                this.clearHistory();
                                //完整的牌 - K
                                let cardKEl = this.getElement(cardTempId);
                                let cardKLeft = cardKEl.offsetLeft;
                                let cardKTop = cardKEl.offsetTop;
                                for (let k = 0; k < completeCardIds.length; k++) {
                                    let completeCardId = completeCardIds[k];
                                    let completeCard = this.cardList[completeCardId];
                                    //设置该牌的状态已完成
                                    completeCard.value.status = 2;
                                    let completeCardEl = this.getElement(completeCardId);
                                    completeCardEl.style.transition = '0.8s';
                                    completeCardEl.style.left = `${ cardKLeft }px`;
                                    completeCardEl.style.top = `${ cardKTop }px`;


                                    this.delayExecute(() => {
                                        if(k === 0){
                                            cardKEl.remove();
                                            let cardIdsCopy = [...cardIds];

                                            this.containerList[containerId].cardIds = cardIdsCopy.filter(id => !(id === cardTempId || completeCardIds.filter(i => i === id).length > 0));
                                            this.delayExecute(() => {
                                                let cardAEl = this.getElement(cardId);
                                                let cardBoxId = this.getNotUsedCardBoxId();
                                                let cardBox = this.cardBoxList[cardBoxId];
                                                cardBox.use = true;
                                                let cardBoxEl = this.getElement(cardBoxId);
                                                cardBoxEl.after(cardAEl);
                                                cardAEl.style.zIndex = 33333;
                                                cardAEl.style.transition = '0.8s';
                                                cardAEl.style.left = `${ cardBoxEl.offsetLeft + (cardBoxEl.clientWidth - cardAEl.clientWidth) / 2 }px`;
                                                cardAEl.style.top = `${ cardBoxEl.offsetTop }px`;

                                                this.delayExecute(() => {
                                                    //获取没有空闲的纸牌盒，则为胜利
                                                    if(this.getNotUsedCardBoxId() === null){
                                                        alert("你赢了")
                                                    }
                                                }, 800)

                                                //调整牌间距
                                                this.adjustCardSpace(containerId);
                                            }, 200)


                                            this.delayExecute(() => {
                                                let containerLastCardId = this.getLastCardByContainerId(containerId);
                                                if(containerLastCardId !== null) {
                                                    let containerLastCard = this.cardList[containerLastCardId];
                                                    containerLastCard.value.status = 1;
                                                    let containerLastCardEl = this.cardElList[containerLastCardId];
                                                    containerLastCardEl.showInfo(containerLastCard.value)
                                                }
                                            }, 200)

                                        } else {
                                            completeCardEl.remove()
                                        }
                                    }, 900)
                                }



                                break;
                            }else{
                                completeCardIds.push(cardTempId);
                            }
                        }



                    }
                    if(!flag){
                        //调整牌间距
                        this.adjustCardSpace(containerId);
                    }
                }

            }
            //允许接收该牌
            return true;
        },
        /**
         * 调整牌间距
         * @param containerId 容器id
         */
        adjustCardSpace(containerId){
            let container = this.containerList[containerId];
            let cardIds = container.cardIds;
            if(cardIds.length === 0){
                return;
            }

            let cardEl = this.getElement(cardIds[0]);
            let containerEl = this.getElement(containerId);
            let left = containerEl.offsetLeft + (containerEl.clientWidth - cardEl.clientWidth) / 2;

            let top = containerEl.offsetTop;
            let cardSpaceKey = Object.keys(this.cardSpace);
            for (let j = cardSpaceKey.length - 1; j >= 0; j--) {
                let key = cardSpaceKey[j];
                if(cardIds.length >= parseInt(key)){
                    for (let j = 0; j < cardIds.length; j++) {
                        let cardIdTemp = cardIds[j];
                        let cardTemp = this.cardList[cardIdTemp];
                        let cardSpace = cardTemp.value.status === 0 || cardTemp.value.status === 2 ? this.cardSpace[key].darkCard : this.cardSpace[key].openDeal;
                        this.moveCard(cardIdTemp, left, top);

                        top += cardSpace;
                    }
                    break;
                }
            }
        },
        /**
         * 在容器中查找纸牌下标(如果不存在则返回-1)
         * @param containerId 容器id
         * @param cardId 纸牌id
         */
        findCardInContainerIndex(containerId, cardId){
            let container = this.containerList[containerId];
            let cardIds = container.cardIds;
            for (let i = 0; cardIds && i < cardIds.length; i++) {
                let cardIdTemp = cardIds[i];
                if(cardIdTemp === cardId){
                    return i;
                }
            }
            return -1;
        },
        /**
         * 通过容器id获取最后一张纸牌id
         * @param containerId 容器id
         * @return 存在返回最好一张牌id，不存在返回null
         */
        getLastCardByContainerId(containerId){
            let container = this.containerList[containerId];
            let cards = container.cardIds;
            if(cards && cards.length > 0){
                return cards[cards.length - 1];
            }
            return null;
        }
    }
}
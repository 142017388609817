<template>
  <div id="app">
    <GameMenu @menu="menuClick" v-model="menuValue"/>
  </div>
</template>

<script>

import GameMenu from "@/components/GameMenu";

import GameMixin from "@/mixins/GameMixin";
import ContainerMixin from "@/mixins/ContainerMixin";
import CardMixin from "@/mixins/CardMixin";

export default {
  name: 'App',
  mixins:[
    GameMixin,
    ContainerMixin,
    CardMixin
  ],
  components:{
    GameMenu
  },
  data(){
    return{
      menuValue: {
        allowRevoke: false
      }
    }
  },
  methods:{
    //菜单点击事件
    menuClick({type, grade}){
      switch (type){
        case 0://选择难度
            this.gameGrade = grade;
            this.clearData();
            this.gameStart();
            break;
        case 1://新游戏
            this.clearData();
            this.gameStart();
            break;
        case 2://撤销游戏
            this.revoke();
            break;
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}


/*--------禁止文字被选中---begin--------*/
*{
  -webkit-touch-callout:none; /*系统默认菜单被禁用*/
  -webkit-user-select:none; /*webkit浏览器*/
  -khtml-user-select:none; /*早期浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none; /*IE10*/
  user-select:none;
}
input{
  -webkit-user-select:auto; /*webkit浏览器*/
}
textarea{
  -webkit-user-select:auto; /*webkit浏览器*/
}
/*--------禁止文字被选中---end--------*/

#app{
  width: 100vw;
  height: 100vh;
  background-image: url("@/assets/green_felt.jpg");
  background-size: 100% 100%;
}
#app:before {
  content: "Jackie - 蜘蛛纸牌";
  font-size: 50px;
  position: absolute;
  margin-left: -80px;
  /*background-color: rgba(255, 255, 0, 0.2);*/
  -webkit-transform:rotate(-45deg);
  opacity: 0.1;
}
#app:before{
  top: 70%;
  left: 70%;
}
#app:after {
  top: 20%;
  left: 20%;
}
</style>
